import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API } from '../../API';

export const processRewards = createAsyncThunk(
  'processRewards',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${API}/refers/process`,{},{withCredentials: true});
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    admin: null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(processRewards.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(processRewards.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(processRewards.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default adminSlice.reducer;
