import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Banner from './components/Banner';
import Features from './components/Features';
import Products from './components/Products';
import Categories from './components/Categories';
import Seller from './components/Reviews/Seller';
import Reviews from './components/Reviews';
import Blogs from './components/Blogs';
import Footer from './components/Footer';
import SellerRegistration from './components/sellerRegister/index';
import Profile from './components/profile/Profile'
import CustomerDashboard from './components/customerDashboard/App'
import OrderHistory from './components/customerDashboard/OrderHistory'
import SellerProfile from './components/sellerDashboard/SellerProfile';
import SellerOrders from './components/sellerDashboard/SellerOrders';
import Checkout from './components/checkout/Checkout';


import AdminDashBoard from './components/Admin/AdminDashboard';
import './App.css';
import Protected from './components/ProtectedRoutes/Protected';
import SellerDashboard from './components/sellerDashboard/SellerDashboard';
import SellerProducts from './components/sellerDashboard/SellerProducts';
import SellerPayment from './components/sellerDashboard/SellerPayment';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from './components/Header2/Navbar';

// import AdminLogin from './components/Admin/AdminLogin';
import RegistrationForm from './components/sellerRegister';
import AdminProtect from './components/ProtectedRoutes/AdminProtect'
import CustomersList from './components/Admin/CustomerLists';
import SellersList from './components/Admin/SellersList';
import Settings from './components/Admin/Settings';
import TermsConditions from './components/Legal/Terms&Condition';
import PrivacyPolicy from './components/Legal/PrivacyPolicy';
import RefundCancellationPolicy from './components/Legal/Refund';
import ShippingDeliveryPolicy from './components/Legal/Shipping';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { fetchUserData } from './Features/user/userSlice';
import Wallet from './components/wallet/Wallet';
import Dashboard from './components/Dashboard/Dashboard';
import ReferEarn from './components/ReferEarn';
import Transactions from './components/Transactions';

function App() {
  const location= useLocation();
  const {pathname}= location;
  const user = useSelector((state) => state.user.user);
  const hideNavbar = pathname.startsWith('/dashboard') || pathname.startsWith('/seller') || pathname.startsWith('/admin');
  const dispatch = useDispatch();

  const [isAuthenticated, setIsAuthenticated] = useState(
   false
  );

  useEffect(() => {
    dispatch(fetchUserData());
  }, [dispatch]);

  useEffect(() => {
    setIsAuthenticated(user !== null);
  }, [user]);

  return (
    <div className="app-container">
      <div className="app">
      <ScrollToTop/>
      {!hideNavbar && <Navbar />}
      
        
      </div>
      <Routes>
        <Route
          path="/"
          element={
            (
              <>
                <Banner />
                
                <Products />
                <Categories />
                {/* <Seller /> */}
                {/* <Reviews /> */}
                {/* <Blogs /> */}
                <Features />
              </>
            )
          }
        />

        {/* Admin Routes */}
        {/* <Route path="/adminLogin" element={<AdminLogin />} /> */}
        <Route path="/auth" element={
            isAuthenticated ? <Navigate to="/" /> : <RegistrationForm />
        }/>
        <Route path="/admin" element={
          <Protected role={'admin'}>
            <AdminDashBoard />
          </Protected>
        }>
          <Route index element={<CustomersList />} />
          {/* <Route path="customers" element={<CustomersList />} />
          <Route path="sellers" element={<SellersList />} />
          <Route path="settings" element={<Settings />} /> */}
        </Route>

        {/* Seller routes */}
        <Route path="/sellerRegister" element={<SellerRegistration />} />
        
        <Route path="/profile" element={
          <Protected role={'customer'}>
            <Profile />
          </Protected>
        } />

        
      {/* Customer Routes */}

            <Route path="/dashboard" element={
              <Protected role={'customer'}>
                <CustomerDashboard />
              </Protected>
            }>
              <Route index element={<Dashboard />} />
              <Route path="profile" element={
                <Protected role={'customer'}>
                  <Profile />
                </Protected>
              } />

              <Route path="referearn" element={
                <Protected role={'customer'}>
                  <ReferEarn />
                </Protected>
              }/>

              <Route path="wallet" element={
                <Protected role={'customer'}>
                  <Wallet />
                </Protected>
              }/>

              <Route path="transactions" element={
                <Protected role={'customer'}>
                  <Transactions />
                </Protected>
              }/>

              <Route path="orders" element={
                <Protected role={'customer'}>
                  <OrderHistory />
                </Protected>
              } />
            </Route>

          {/* Protected Seller Routes */}
        

          <Route path="/seller" element={
              <SellerDashboard />
            }>
          <Route path="profile" element={
            <Protected role={'seller'}>
               <SellerProfile />
            </Protected>
            } />
          <Route path="orders" element={
            <Protected role={'seller'}>
            <SellerOrders />
            </Protected>
            } />
          <Route path="products" element={
            <Protected role={'seller'}>
          <SellerProducts/>
            </Protected>
          } />
          <Route path="payment" element={
            <Protected role={'seller'}>
          <SellerPayment/>
            </Protected>
          } />
        </Route>

        
        {/* <Route path="/sellerProfile" element={<SellerProfile />} />  */}
        {/* <Route path="/payment" element={<Payment />} />
        <Route path="/order" element={<Order />} />
        <Route path="/add-product" element={<AddProduct />} />
        <Route path="/remove-product" element={<RemoveProduct />} />
        <Route path="/update-product" element={<UpdateProduct />} /> */}

        
         
        <Route path="/checkout" element={
          <Protected role='customer'>
          <Checkout />
          </Protected>
          } />

          <Route path='/termsAndConditions' element={<TermsConditions/>} />
          <Route path='/privacyPolicy' element={<PrivacyPolicy/>} />
          <Route path='/refundCancellations' element={<RefundCancellationPolicy/>} />
          <Route path='/shippingDeliveryPolicy' element={<ShippingDeliveryPolicy/>} />
      </Routes>
      {!hideNavbar && <Footer />}
    </div>
  );
}

export default App;
