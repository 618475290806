import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderHistory } from '../../Features/order/orderSlice';
import Loader from '../Loader/Loader';

export default function Orders() {
  const dispatch = useDispatch();
  const { orders, loading, error } = useSelector((state) => state.orders);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(getOrderHistory());
  }, [dispatch]);

  const openModal = (order) => {
    setSelectedOrder(order);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedOrder(null);
  };

  if (orders && orders.message === "No order history found") {
    return <h1 className="text-2xl text-center mt-20">{orders.message}</h1>;
  }

  if(loading){
    return <Loader/>
  }

  return (
    <div className='p-10'>
      <h1 className="text-3xl font-bold mb-4">My Orders</h1>
      <div className="text-2xl bg-white shadow rounded-lg overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Order ID</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 text-black">
            {orders.orders && orders.orders.map((order) => (
              <tr key={order._id}>
                <td className="px-4 py-3 whitespace-nowrap text-sm">{order._id}</td>
                <td className="px-4 py-3 whitespace-nowrap text-sm">{new Date(order.updatedAt).toLocaleDateString()}</td>
                <td className="px-4 py-3 whitespace-nowrap text-sm">${order.totalPrice.toFixed(2)}</td>
                <td className="px-4 py-3 whitespace-nowrap text-sm">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    order.status === 'Delivered' ? 'bg-green-100 text-green-800' :
                    order.status === 'Processing' ? 'bg-yellow-100 text-yellow-800' :
                    order.status === 'Shipped' ? 'bg-blue-100 text-blue-800' :
                    'bg-red-100 text-red-800'
                  }`}>
                    {order.status}
                  </span>
                </td>
                <td className="px-4 py-3 whitespace-nowrap">
                  <button
                    className="text-blue-500 hover:underline"
                    onClick={() => openModal(order)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showModal && selectedOrder && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white rounded-lg w-full max-w-lg md:w-2/3 p-6 relative">
            <h2 className="text-2xl font-bold mb-4">Order Details</h2>
            <button
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
              onClick={closeModal}
            >
              &times;
            </button>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Left Column: Shipping Details */}
              <div className="border-r pr-4">
                <h3 className="font-semibold text-lg mb-2">Shipping Details</h3>
                <p><strong>Name:</strong> {selectedOrder.shippingDetails.name}</p>
                <p><strong>Address:</strong> {selectedOrder.shippingDetails.address}</p>
                <p><strong>City:</strong> {selectedOrder.shippingDetails.city}</p>
                <p><strong>Postal Code:</strong> {selectedOrder.shippingDetails.postalCode}</p>
              </div>
              
              {/* Right Column: Products */}
              <div>
                <h3 className="font-semibold text-lg mb-2">Products</h3>
                {selectedOrder.products.map((product, index) => (
                  <div key={index} className="flex justify-between items-center mb-2">
                    <img src={product.image} alt="" className="w-12 h-12 object-cover rounded mr-3"/>
                    <p className="flex-1">{product.name}</p>
                    <p className="whitespace-nowrap">{product.quantity} x ₹{product.price.toFixed(2)}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
