import React from 'react';
import {Link} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faEnvelope,
  faMapMarkerAlt,
  faPhone,
  faShoppingBasket,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import './Footer.css';

export default function Footer() {
  return (
<footer className="footer" id="footer">

  
  <div className="box-container">
    <div className="box">
      <FontAwesomeIcon icon={['fas', 'faUser']} />
      <h3>
      <i>
          <FontAwesomeIcon icon={faShoppingBasket} />
        </i> Shriveg&apos;s Grocery
        <br />
      </h3>
      <p className='font-medium'>
        Your One-Stop Shop for Fruits, Vegetables, Snacks, and More!
      </p>
      <div className="share">
        <a href="/">
          <i>
            <FontAwesomeIcon className="fa-icon" icon={faFacebookF} />
          </i>
        </a>
        <a href="/">
          <i>
            <FontAwesomeIcon className="fa-icon" icon={faTwitter} />
          </i>
        </a>
        <a href="/">
          <i>
            <FontAwesomeIcon className="fa-icon" icon={faInstagram} />
          </i>
        </a>
        <a href="/">
          <i>
            <FontAwesomeIcon className="fa-icon" icon={faLinkedin} />
          </i>
        </a>
      </div>
    </div>

    <div className="box">
      <h3>Quick Info</h3>
      <Link to="/" onClick={() => window.scrollTo(0, 0)} className="links">
        <i>
          <FontAwesomeIcon icon={faArrowRight} />
        </i>
        Home
      </Link>
      <a href="#features" className="links">
        <i>
          <FontAwesomeIcon icon={faArrowRight} />
        </i>
        Features
      </a>
      <a href="#products" className="links">
        <i>
          <FontAwesomeIcon icon={faArrowRight} />
        </i>
        Products
      </a>
      <a href="#categories" className="links">
        <i>
          <FontAwesomeIcon icon={faArrowRight} />
        </i>
        Categories
      </a>
    </div>

    <div className="box">
      <h3>Legal</h3>
      <Link to="/termsAndConditions" className="links">
        <i>
          <FontAwesomeIcon icon={faArrowRight} />
        </i>
        Terms and Conditions
      </Link>
      <Link to="/privacyPolicy" className="links">
        <i>
          <FontAwesomeIcon icon={faArrowRight} />
        </i>
        Privacy Policy
      </Link>
      <Link to="/refundCancellations" className="links">
        <i>
          <FontAwesomeIcon icon={faArrowRight} />
        </i>
        Refunds/Cancellations
      </Link>
      <Link to="/shippingDeliveryPolicy" className="links">
        <i>
          <FontAwesomeIcon icon={faArrowRight} />
        </i>
        Shipping/Delivery Policy
      </Link>
    </div>

    <div className="box">
      <h3>Contact Info</h3>
      <a href="tel:07800009965" className="links">
        <i>
          <FontAwesomeIcon icon={faPhone} />
        </i>
        +91-78 0000 9965
      </a>
      <a href="mailto:shrivegofficial@gmail.com" className="links" id="emailLink">
        <i>
          <FontAwesomeIcon icon={faEnvelope} />
        </i>
        shrivegofficial@gmail.com
      </a>
      <a href="https://maps.app.goo.gl/JydvyBWLjZC6juWH8" target='_blank' rel="noopener noreferrer" className="links">
        <i>
          <FontAwesomeIcon icon={faMapMarkerAlt} />
        </i>
        Chhawni, Near Registry office Padrauna District, Kushinagar UP. 274304 India.
      </a>
    </div>

    <div className="box justify-center">
      <h3>Payment Methods</h3>
      <img src="image/payment.png" alt="Payment Methods" />
    </div>
  </div>
</footer>

  );
}
