import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { processRewards } from '../../Features/admin/adminSlice';
import { toast } from 'react-toastify';

export default function CustomersList() {
  const dispatch = useDispatch();
  const { isLoading, isError } = useSelector((state) => state.admin);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      dispatch(processRewards());
    } catch (error) {
      toast.error("An error occurred.", {
        position: "bottom-center",
        autoClose: 5000,
        theme: "dark",
      });
    }
  };

  return (
    <div>
      <div className="bg-white shadow-lg rounded-lg mt-10 mb-8 p-8 max-w-6xl mx-auto">
        {/* Welcome Section */}
        <div className="p-6 bg-green-50 border border-green-200 rounded-lg shadow-md ">
          <h2 className="text-2xl font-semibold text-green-800">Welcome Back!</h2>
          <p className="text-gray-700 mt-2">Here are some quick links to get you started with your grocery shopping.</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-4 sm:grid-cols-2 gap-6 mt-10">
            {/* Shop Now Card */}
            <div className="p-5 bg-yellow-50 border border-yellow-200 rounded-lg shadow-md text-center">
              <h3 className="font-semibold text-yellow-800">Distribute Rewards</h3>
              <p className="text-gray-600">Distribute rewards to all referal users who are join by referal code.</p>
              <button 
                onClick={submitHandler}
                className="mt-3 bg-yellow-600 text-white py-2 px-4 rounded-lg hover:bg-yellow-500 transition duration-200 transform hover:scale-105">
                 {isLoading ? <span className="spinner"></span> : "Process Rewards"}
              </button>
            </div>
        </div>
      </div>
    </div>
  );
}