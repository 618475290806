import React, { useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faShoppingBasket,
  faUser,
  faClipboardList,
  faBox,
  faMoneyBillWave,
  faSignOutAlt,
  faBars,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../Features/user/userSlice';
import { toast, Slide } from 'react-toastify';
import { resetSellerProducts } from '../../Features/seller/sellerSlice';

export default function SellerDashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  // console.log("User==> ",user);
  

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem('user');
    dispatch(resetSellerProducts())
    toast.success("Logout successful", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Slide,
      });
    
  };

  const sidebarItems = [
    { id: 'profile', name: 'Seller Profile', icon: faUser, path: '/seller/profile' },
    { id: 'orders', name: 'Orders', icon: faClipboardList, path: '/seller/orders' },
    { id: 'products', name: 'Products', icon: faBox, path: '/seller/products' },
    { id: 'payment', name: 'Payment', icon: faMoneyBillWave, path: '/seller/payment' },
  ];

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <aside
        className={`
          ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'}
          fixed inset-y-0 left-0 z-50 w-64 bg-green-700 text-white transform transition-transform duration-300 ease-in-out
          md:relative md:translate-x-0
        `}
      >
        <div className="flex justify-between items-center p-4 border-b border-green-600">
          {/* <h2 className="text-2xl font-semibold">Seller Dashboard</h2> */}
          <button
            onClick={toggleSidebar}
            className="md:hidden text-white hover:text-green-200"
            aria-label="Close sidebar"
          >
            <FontAwesomeIcon icon={faTimes} className="w-6 h-6" />
          </button>
        </div>
        <div className="p-4 text-xl text-center">

        <div className="logo">
        <i>
          <FontAwesomeIcon icon={faShoppingBasket} />
        </i>
        Shriveg&apos;s Grocery
      </div>

          <p className="text-lg text-green-200">Welcome, {user?.name}</p>
        </div>
        <nav className="mt-4 text-2xl">
          <ul>
            {sidebarItems.map((item) => (
              <li key={item.id}>
                <Link
                  to={item.path}
                  className={`flex items-center px-4 py-2 text-white hover:bg-green-600 ${
                    location.pathname === item.path ? 'bg-green-600' : ''
                  }`}
                  onClick={() => setSidebarOpen(false)}
                >
                  <FontAwesomeIcon icon={item.icon} className="w-5 h-5 mr-3" />
                  {item.name}
                </Link>
              </li>
            ))}
            <li>
              <button
                onClick={handleLogout}
                className="flex items-center w-full px-4 py-2 text-white hover:bg-green-600"
              >
                <FontAwesomeIcon icon={faSignOutAlt} className="w-5 h-5 mr-3" />
                Logout
              </button>
            </li>
          </ul>
        </nav>
      </aside>

      {/* Main Content Area */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Header */}
        <header className="bg-white shadow-sm">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex items-center justify-between">
            <button
              onClick={toggleSidebar}
              className="md:hidden text-gray-500 hover:text-gray-700"
              aria-label="Open sidebar"
            >
              <FontAwesomeIcon icon={faBars} className="w-6 h-6" />
            </button>
            <h1 className="text-xl font-semibold text-gray-800">Seller Dashboard</h1>
          </div>
        </header>

        {/* Main content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <Outlet />
          </div>
        </main>
      </div>

      {/* Overlay */}
      {sidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden"
          onClick={toggleSidebar}
          aria-hidden="true"
        ></div>
      )}
    </div>
  );
}