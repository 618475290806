import { createSlice } from "@reduxjs/toolkit";

// Load cart from localStorage or set initial state
const storedCart = JSON.parse(localStorage.getItem('cart')) || [];

const initialState = {
  cart: storedCart.cart || [],
  totalQuantity: storedCart.totalQuantity || 0,
  totalPrice: storedCart.totalPrice || 0,
};

const saveCartToLocalStorage = (state) => {
  localStorage.setItem('cart', JSON.stringify({
    cart: state.cart,
    totalQuantity: state.totalQuantity,
    totalPrice: state.totalPrice,
  }));
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const newItem = action.payload;
      const existingItem = state.cart.find(item => item.id === newItem._id);

      state.totalQuantity++;

      if (!existingItem) {
        state.cart.push({
          id: newItem._id,
          name: newItem.name,
          price: newItem.price,
          quantity: 1,
          seller: newItem.seller,
          totalPrice: newItem.price,
          image: newItem.image,
        });
      } else {
        existingItem.quantity++;
        existingItem.totalPrice += newItem.price;
      }

      state.totalPrice += newItem.price;
      saveCartToLocalStorage(state);
    },
    removeFromCart: (state, action) => {
      const id = action.payload;
      const existingItem = state.cart.find(item => item.id === id);

      if (existingItem) {
        state.totalQuantity -= existingItem.quantity;
        state.totalPrice -= existingItem.totalPrice;
        state.cart = state.cart.filter(item => item.id !== id);
      }

      saveCartToLocalStorage(state);
    },
    clearCart: (state) => {
      state.cart = [];
      state.totalQuantity = 0;
      state.totalPrice = 0;
      localStorage.removeItem('cart');
    },
    incrementQuantity: (state, action) => {
      const item = state.cart.find(i => i.id === action.payload);
      if (item) {
        item.quantity += 1;
        item.totalPrice += item.price;
        state.totalPrice += item.price;
        state.totalQuantity += 1; // Increment totalQuantity
      }
      saveCartToLocalStorage(state);
    },
    decrementQuantity: (state, action) => {
      const item = state.cart.find(i => i.id === action.payload);
      if (item) {
        if (item.quantity > 1) {
          item.quantity -= 1;
          item.totalPrice -= item.price;
          state.totalPrice -= item.price;
          state.totalQuantity -= 1; // Decrement totalQuantity
        } else {
          // Remove item from cart if quantity is 1
          state.cart = state.cart.filter(i => i.id !== action.payload);
          state.totalPrice -= item.price;
          state.totalQuantity -= 1; // Adjust totalQuantity
        }
      }
      saveCartToLocalStorage(state);
    },
  },
});

export const { addToCart, removeFromCart, clearCart, incrementQuantity, decrementQuantity } = cartSlice.actions;
export default cartSlice.reducer;
