import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API } from '../../API';

export const rechargeWallet = createAsyncThunk(
    'rechargeWallet',
    async (walletData, { rejectWithValue }) => {
      try {
        const response = await axios.post(`${API}/wallet/recharge`,
            walletData,
            {withCredentials: true}
        );
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
);

export const makePayment = createAsyncThunk(
    'makePayment',
    async (recharge_id, { rejectWithValue }) => {
      try {
        const response = await axios.post(`${API}/payments/razorpay`,
            {wallet_recharge_id: recharge_id},
            {withCredentials: true}
        );
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
);

export const fetchWalletTransaction = createAsyncThunk(
  'fetchWalletTransaction',
  async (pagination, { rejectWithValue }) => {
      try {
          const data = await axios.get(`${API}/wallet?skip=${pagination.skip}&limit=${pagination.limit}`,{withCredentials: true}
          );
          return data;
      } catch (error) {
          if (error.response) {
              return rejectWithValue(error.response.data);
          } else if (error.request) {
              return rejectWithValue("No response received from server");
          } else {
              return rejectWithValue("Error setting up request");
          }
      }
  }
);

const wallet = createSlice({
    name: 'wallet',
    initialState: {
      wallet: null,
      walletHistory: [],
      walletHistoryCount: 0,
      loading: false,
      listLoading: false,
      error: null,
    },
    reducers: {
      clearWalletState: (state) => {
        state.walletHistory = [];
        state.listLoading = false;
        state.walletHistoryCount = 0;
        state.isReferError = false;
        state.errorMessage = null;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(rechargeWallet.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(rechargeWallet.fulfilled, (state, action) => {
          state.loading = false;
          state.wallet = action.payload;
        })
        .addCase(rechargeWallet.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
      builder
        .addCase(makePayment.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(makePayment.fulfilled, (state, action) => {
          state.loading = false;
          state.wallet = action.payload;
        })
        .addCase(makePayment.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
      builder
        .addCase(fetchWalletTransaction.pending, (state) => {
          state.listLoading = true;
          state.error = null;
        })
        .addCase(fetchWalletTransaction.fulfilled, (state, action) => {
          state.listLoading = false;
          state.walletHistory = state.walletHistory.concat(...action.payload.data.doc);
          state.walletHistoryCount = action.payload.data.count;
        })
        .addCase(fetchWalletTransaction.rejected, (state, action) => {
          state.listLoading = false;
          state.error = action.payload;
        });
    },
});

export const { clearWalletState } = wallet.actions;
export default wallet.reducer;
