import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {API} from '../../API'

export default function Profile() {
  const user = useSelector((state) => state.user.user);
  console.log("user", user)
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [formData, setFormData] = useState({
    name: user.name || '',
    email: user.email || '',
    phone: user.phone || '',
    password: '',
    address: {
      street: user.address?.street || '',
      city: user.address?.city || '',
      postalCode: user.address?.postalCode || ''
    }
  });

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setFormData({  name: user.name || '',
      email: '',
      phone: '',
      password: '',
      address: {
        street: '',
        city: '',
        postalCode: ''
      }})
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('address.')) {
      const addressField = name.split('.')[1]; 
      setFormData((prev) => ({
        ...prev,
        address: { ...prev.address, [addressField]: value }
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const setAuthHeader = () => {
    const user = localStorage.getItem('user');
    const { token } = user ? JSON.parse(user) : {};
    return {
      headers: {
        Authorization: token ? `Bearer ${token}` : '', 
      },
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(
        `${API}/api/customer/update-details`, 
        {
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          password: formData.password,
          address: formData.address 
        },
        setAuthHeader()
      );

      if (response.status === 200) {
        toast.success('Profile updated successfully Changes will reflect on next login !', {
          position: "bottom-center",
          autoClose: 5000,
          theme: "dark",
          transition: Slide,
        });
        closeModal(); 
      } else {
        toast.error('Failed to update profile.', {
          position: "bottom-center",
          autoClose: 5000,
          theme: "dark",
          transition: Slide,
        });
      }
    } catch (error) {
      console.log('error ',error);
      const {message}= error.response.data
      toast.error(message || 'An error occur while updating the profile', {
        position: "bottom-center",
        autoClose: 5000,
        theme: "dark",
        transition: Slide,
      });
    }
  };

  return (
<div>
  <div className="bg-white shadow-lg rounded-lg mt-10 p-8 max-w-6xl mx-auto">
    <div className="flex items-center space-x-4 mb-6">
      <div>
        <h2 className="text-2xl font-semibold text-black">{user.fullName}</h2>
      </div>
    </div>
    
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
      <div className="text-black">
        <h3 className="text-xl font-semibold mb-4">Personal Information</h3>
        <p><strong>Phone:</strong> {user.phone?.value || 'Not provided'}</p>
        <p><strong>Email:</strong> {user.email?.value || 'Not provided'}</p>
        <p><strong>Address:</strong> {user.address ? `${user.address.street}, ${user.address.city}, ${user.address.postalCode}` : 'Not provided'}</p>
      </div>
      
      <div className="text-black">
        <h3 className="text-xl font-semibold mb-4">Account Information</h3>
        <p><strong>Member Since:</strong> {new Date(user.createdAt).toLocaleDateString()}</p>
        <p><strong>Last Updated:</strong> {new Date(user.updatedAt).toLocaleDateString()}</p>
      </div>
    </div>

    <div className="mt-8 p-4 border rounded-md bg-gray-50">
      <h3 className="text-xl font-semibold mb-4">Referral Code</h3>
      <div className="flex items-center justify-between">
        <span className="text-lg font-medium text-black">{user.referral_code
 || 'No referral code available'}</span>
        <button
          onClick={() => {
            navigator.clipboard.writeText(`https://shriveg.com/auth?referral_code=${user.referral_code}`
            );
            alert('Referral code copied to clipboard!');
          }}
          className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-500 transition duration-300"
        >
          Copy
        </button>
      </div>
    </div>

    <button
      className="mt-6 bg-green-600 text-white py-2 px-6 rounded hover:bg-green-500 transition-all duration-300"
      onClick={openModal}
    >
      Update Profile
    </button>

    {/* Modal for updating profile */}
    {isModalOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
          <h3 className="text-lg font-bold mb-4">Update Profile</h3>

          <form className="space-y-4" onSubmit={handleSubmit}>
            {/* Form fields for updating profile */}
            <div>
              <label className="block text-sm font-medium text-gray-700">Full Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your full name"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Email Address</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Phone Number</label>
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Enter your phone number"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Password</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Enter your password"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <h3 className="text-lg font-medium mb-2">Address Information</h3>

            <div>
              <label className="block text-sm font-medium text-gray-700">Street</label>
              <input
                type="text"
                name="address.street"
                value={formData.address.street}
                onChange={handleChange}
                placeholder="Enter your street"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">City</label>
              <input
                type="text"
                name="address.city"
                value={formData.address.city}
                onChange={handleChange}
                placeholder="Enter your city"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Postal Code</label>
              <input
                type="text"
                name="address.postalCode"
                value={formData.address.postalCode}
                onChange={handleChange}
                placeholder="Enter your postal code"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div className="flex justify-end mt-4">
              <button
                type="button"
                className="text-gray-600 mr-2"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-500 transition-all duration-300"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    )}
  </div>
</div>


  );
}
