// Categories
import React, { useState } from 'react';
import './Categories.css';

export default function Categories() {
  const categories = [
    { name: 'Vegetables', image: 'image/cat-1.png', discount: 'upto 45% off' },
    { name: 'Fruits', image: 'image/cat-2.png', discount: 'upto 45% off' },
    { name: 'Dairy Products', image: 'image/cat-3.png', discount: 'upto 45% off' },
    { name: 'Bakery Products', image: 'image/cat-5.png', discount: 'upto 45% off' },
    { name: 'Snacks', image: 'image/cat-6.png', discount: 'upto 45% off' },
    { name: 'Beverages', image: 'image/cat-7.png', discount: 'upto 45% off' },
    { name: 'Spices', image: 'image/cat-8.png', discount: 'upto 45% off' },
    { name: 'Ready-to-Eat', image: 'image/cat-9.png', discount: 'upto 45% off' },
    { name: 'Frozen Food', image: 'image/cat-10.png', discount: 'upto 45% off' }
  ];

  const itemsPerRow = 3;
  const [visibleRows, setVisibleRows] = useState(2);

  // Calculate number of items to display based on visible rows
  const visibleCategories = categories.slice(0, visibleRows * itemsPerRow);

  const handleShowMore = () => {
    setVisibleRows((prevRows) => prevRows + 1);
  };

  return (
    <section className="categories bg-[#1d2634]" id="categories">
      <h1 className="heading">
        products
        {' '}
        <span>categories</span>
      </h1>
      <div className="box-container">
        {visibleCategories.map((category, index) => (
          <div className="box flex flex-col justify-center items-center" key={index}>
            <img src={category.image} alt={category.name} />
            <h3>{category.name}</h3>
            <p>{category.discount}</p>
            <button type="button" className="btn">See More</button>
          </div>
        ))}
      </div>
      {visibleCategories.length < categories.length && (
        <div className="more-sellers">
          <button type="button" className="btn more-btn" onClick={handleShowMore}>More</button>
        </div>
      )}
    </section>
  );
}
