// Features
import React from "react";
import { Link } from "react-router-dom";
import "./Features.css";

export default function Features() {
  const features = [
    {
      title: "Fresh and Organic",
      image: "image/feature-img-1.png",
      description: "Enjoy farm-fresh produce grown without chemicals!",
    },
    {
      title: "Free Delivery",
      image: "image/feature-img-2.png",
      description: "Get your orders delivered to your door at no cost!",
    },
    {
      title: "Easy Payment",
      image: "image/feature-img-3.png",
      description: "Choose from multiple payment options hassle-free!",
    },
  ];

  return (
    <section className="features bg-[#1d2634]" id="features">
      <div className="content">
        <h1 className="heading">
          our <span>features</span>
        </h1>
        <div className="box-container flex flex-wrap justify-center sm:justify-between ">
          {features.map((feature, index) => (
            <div
              className="box mt-4 flex flex-col items-center text-center w-full lg:w-[320px]"
              key={index}
            >
              <img src={feature.image} alt={feature.title} />
              <h3 className="text-xl text-gray-900 mb-2">{feature.title}</h3>
              <p className="text-gray-700 mb-4">{feature.description}</p>
              <Link
                to="/"
                className="btn bg-green-500 hover:bg-green-600 text-white font-medium py-2 px-4 rounded-md"
              >
                Read More
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
