import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../Loader/Loader';
import { fetchTransactions } from '../../Features/transaction/transactionSlice';

export default function Transactions() {
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(0);
  const [limit] = useState(10);
  const [hasMore, setHasMore] = useState(true);
  const { loading, transactionHistory, transactionHistoryCount } = useSelector((state) => state.transaction);
  console.log("first", transactionHistory);
  const loadMore = () => {
    const nextSkip = skip + limit;
    dispatch(fetchTransactions({ skip, limit })).then(() => {
      setSkip(nextSkip);
  
      if (transactionHistoryCount > 0 && nextSkip >= transactionHistoryCount) {
        setHasMore(false);
      }
    });
  };
  
  useEffect(() => {
    if (skip === 0) {
      loadMore();
    }
  }, []);

  if(loading){
    return <Loader/>
  }

  return (
    <div className="mx-auto p-10">
      <h2 className="text-3xl font-bold text-green-700 mb-6">Transaction History</h2>

      <div className="bg-gray-100 p-6 rounded-lg shadow-lg w-full overflow-x-auto">
        <table className="min-w-full text-sm text-left text-gray-600">
          <thead className="bg-gray-200">
            <tr>
              <th className="px-4 py-2 font-semibold">Sr.No</th>
              <th className="px-4 py-2 font-semibold">Date</th>
              <th className="px-4 py-2 font-semibold">Payment Type</th>
              <th className="px-4 py-2 font-semibold">Payment Id</th>
              <th className="px-4 py-2 font-semibold">Amount</th>
              <th className="px-4 py-2 font-semibold">Status</th>
            </tr>
          </thead>
          <tbody>
            {transactionHistory.length > 0 ? (
              transactionHistory.map((transaction, index) => (
                <tr
                  key={transaction._id}
                  className="border-b hover:bg-gray-50 transition-all duration-200"
                >
                  <td className="px-4 py-2">{index + 1}</td>
                  <td className="px-4 py-2">
                      {new Date(transaction.createdAt).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                      })}
                  </td>
                  <td className="px-4 py-2 capitalize">{transaction.notes.payment_type}</td>
                  <td className="px-4 py-2 capitalize">{transaction.payment_id}</td>
                  <td className="px-4 py-2 capitalize">₹{transaction.api_request.amount / 100}</td>
                  <td
                    className={`px-4 py-2 ${
                      transaction.status === 'success'
                        ? 'text-green-600'
                        :  'text-red-600'
                    }`}
                  >
                    {transaction.status}
                  </td>
                  <td className="px-4 py-2">{transaction.method}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="px-4 py-2 text-center text-gray-600">
                  No transactions available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
        {hasMore && (
          <div className="flex justify-center mt-6">
            <button
              onClick={loadMore}
              className="bg-green-600 text-white px-6 py-3 rounded-lg hover:bg-green-700 transition-all duration-300"
            >
              Load More
            </button>
          </div>
        )}
    </div>
  );
}
