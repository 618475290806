import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSellerOrders } from '../../Features/seller/sellerSlice';
import Loader from '../Loader/Loader';

export default function SellerPayment() {
  const { orders, loading, error } = useSelector((state) => state.seller);
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getSellerOrders());
  }, [dispatch]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <h2 className="text-2xl font-bold mb-4">Payment</h2>

      <div className="bg-white shadow overflow-x-auto rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Payment ID</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Payment Method</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 text-lg text-black">
            {orders ? (
              orders.orders.map((payment) => (
                <tr key={payment.id} className="text-sm sm:text-base">
                  <td className="px-6 py-4 whitespace-nowrap">{payment.paymentId}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{payment.customerName}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{new Date(payment.updatedAt).toLocaleDateString()}</td>
                  <td className="px-6 py-4 whitespace-nowrap">₹{payment.totalPrice}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{payment.paymentMethod}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span
                      className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        payment.status === 'Completed'
                          ? 'bg-green-100 text-green-800'
                          : 'bg-yellow-100 text-yellow-800'
                      }`}
                    >
                      {payment.paymentStatus}
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="px-6 py-4 text-center text-gray-500">
                  No Payment details found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
