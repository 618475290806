import React from "react";

const TermsConditions = () => {
  return (
    <div className="container mx-auto px-24 py-10 mt-24 bg-white">
      <h1 className="text-4xl font-bold text-center text-green-800 mb-10">Terms and Conditions</h1>
      <p className="text-gray-800 mb-4 text-2xl">
        Thank you for accessing/shopping at <span className="font-bold">www.shriveg.com.</span> This site is owned by <span className="font-bold">Shriveg Homemade Service Pvt Ltd </span>
        (hereinafter referred to as <span className="font-bold">“www.shriveg.com”).</span> By accessing, shopping on this site, you indicate your 
        unconditional acceptance of these terms & conditions. We reserve this right, in our sole discretion, to update 
        or revise these terms & conditions. Continued use of the site following the posting of any changes to the ‘terms 
        & conditions’, constitutes your acceptance of those changes.
      </p>
      
      <h2 className="text-3xl font-semibold text-green-700 underline mt-6 mb-4">1. Eligibility to use our site</h2>
      <p className="text-gray-800 mb-4 text-2xl">
        Use of the Site is available only to persons who can legally enter into contracts under applicable laws. Persons 
        who are “incompetent to contract”, within the meaning of the Indian Contract Act, 1872 including un-discharged 
        insolvents etc. are not eligible to use the Site. “www.shriveg.com” reserves the right to terminate your access 
        to the Site if it discovers that you are under the age of 18 years or suffer from any other disability, as 
        recognized under the Indian Contract Act, 1872.
      </p>
      
      <h2 className="text-3xl font-semibold text-green-700 underline  mt-6 mb-4">2. Membership</h2>
      <p className="text-gray-800 mb-4 text-2xl">
        Although it's not essential to have an account to shop with “www.shriveg.com”, you can shop as a guest. As a 
        member, you agree to provide true, accurate, current, and complete information about yourself as prompted by 
        the site's registration form. Registration where prohibited under any law shall be void.
      </p>
      
      <h2 className="text-3xl font-semibold text-green-700 underline  mt-6 mb-4">3. Electronic Communications</h2>
      <p className="text-gray-800 mb-4 text-2xl">
        When you use the site or send emails or other data, information, or communicate to us, you agree and understand 
        that you are communicating with us electronically and give your consent to receive communications electronically 
        from us periodically, when required.
      </p>
      
      <h2 className="text-3xl font-semibold text-green-700 underline  mt-6 mb-4">4. Reviews, Feedback, Submissions</h2>
      <p className="text-gray-800 mb-4 text-2xl">
        All reviews, comments, feedback, postcards, suggestions, ideas, and other submissions disclosed, submitted or 
        offered to “www.shriveg.com” directly or otherwise disclosed, submitted or offered in connection with your use 
        of this Site will remain “www.shriveg.com” property.
      </p>
      
      <p className="text-gray-800 mb-4 text-2xl">
        “www.shriveg.com” will be entitled to use, reproduce, disclose, modify, adapt, create derivative works from, 
        publish, display and distribute any comments you submit for any purpose whatsoever, without restriction and 
        without compensating you in any way.
      </p>
      
      <h2 className="text-3xl font-semibold text-green-700 underline  mt-6 mb-4">5. Accuracy of Content/ Information of Products on the Web Site</h2>
      <p className="text-gray-800 mb-4 text-2xl">
        While “www.shriveg.com” strives to provide accurate product and pricing information, typographical errors may 
        occur. In the event that a product is listed at an incorrect price or with incorrect information due to an error 
        in pricing or product information, “www.shriveg.com” shall have the right, in our sole discretion, to modify the 
        price of the products, or information of the products or to refuse or cancel any orders placed for that product, 
        unless the product has already been dispatched.
      </p>
    </div>
  );
};

export default TermsConditions;
