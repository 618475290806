import React from 'react';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../Features/cart/cartSlice'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';

const ProductCard = ({ product }) => {
  const dispatch = useDispatch();

  // handle "Add to Cart"
  const handleAddToCart = () => {
    console.log("Cart ",product);
    
    dispatch(addToCart(product)); 
  };

  return (
    <div className="box flex flex-col items-center text-center">
      <img src={product.image} alt={product.name} />
      <h3>{product.name}</h3>
      <div className="price">₹{product.price}/-</div>
      {/* <div className="stars">
        {[...Array(Math.floor(product.rating))].map((_, i) => (
          <FontAwesomeIcon key={i} icon={faStar} />
        ))}
        {product.rating % 1 !== 0 && <FontAwesomeIcon icon={faStarHalfAlt} />}
      </div> */}
      <button type="button" className="btn" onClick={handleAddToCart}>
        Add to Cart
      </button>
    </div>
  );
};

export default ProductCard;
