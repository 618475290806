import React from 'react';

const OrderModal = ({ show, handleClose, orders }) => {
    console.log("OrderModal ",orders);
    
  if (!show) return null; // Hide modal if show is false

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-2/3 lg:w-1/2">
        <div className="flex justify-between items-center px-6 py-4 border-b">
          <h2 className="text-xl font-semibold">Order Details</h2>
          <button onClick={handleClose} className="text-gray-500 hover:text-gray-800">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="p-6 space-y-4">
            <div className="order-container">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* Left column - Shipping Details */}
                <div>
                  <h5 className="text-lg font-semibold">Shipping Details</h5>
                  <p><strong>Name:</strong> {orders.shippingDetails.name}</p>
                  <p><strong>Address:</strong> {orders.shippingDetails.address}</p>
                  <p><strong>City:</strong> {orders.shippingDetails.city}</p>
                  <p><strong>Postal Code:</strong> {orders.shippingDetails.postalCode}</p>
                  <p><strong>Payment Method:</strong> {orders.paymentMethod}</p>
                </div>

                {/* Right column - Product Details */}
                <div>
                  <h5 className="text-lg font-semibold">Products</h5>
                  {orders && orders.products.map((product, idx) => (
                    <div key={idx} className="flex justify-evenly items-center mb-2">
                      <img
                        src={product.image}
                        alt={product.name}
                        className="w-12 h-12 object-cover rounded mr-3"
                      />
                      <p><strong>{product.name}</strong></p>
                      <p><strong>{product.quantity} piece</strong></p>
                    </div>
                  ))}
                </div>
              </div>
              <hr className="my-4" />
            </div>
        </div>
        <div className="flex justify-end px-6 py-4 border-t">
          <button 
            onClick={handleClose} 
            className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderModal;
