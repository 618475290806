// Banner
import React from 'react';
import './Banner.css';

export default function Banner() {
  return (
    <section
      className="banner"
      id="banner"
      style={{
        background: 'url("../image/banner22.jpg") no-repeat',
        backgroundSize: 'bottom',
      }}
    >
      <div className="content">
        <h3>
          fresh and
          {' '}
          <span>organic</span>
          {' '}
          products
        </h3>
        <button type="button" className="btn">
          shop now
        </button>
      </div>
    </section>
  );
}
