import React, { useState, useEffect } from 'react';
import './Products.css';
import ProductCard from '../ProductCard/ProductCard';
import axios from 'axios';
import Loader from '../Loader/Loader';
import { API } from '../../API';

export default function Products() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${API}/products`);
        setProducts(response.data.doc || []);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const visibleProducts = showAll ? products : products.slice(0, 6);

  const handleShowAll = () => {
    setShowAll(true);
  };

  return (
    <section className="products bg-[#1d2634] relative" id="products">
      <h1 className="heading text-white">
        Our <span>Products</span>
      </h1>

      {loading ? (
        <div className="relative" style={{ height: '400px' }}>
          <Loader />
        </div>
      ) : (
        products.length > 0 && (
          <div className="products-slider wrapper grid grid-cols-1 gap-6 p-4 sm:grid-cols-2 lg:grid-cols-3">
            {visibleProducts.map((product, index) => (
              <div key={product._id || index} className="bg-white product-card border rounded-lg shadow-md p-4">
                <ProductCard product={product} />
              </div>
            ))}
          </div>
        )
      )}

      {!showAll && products.length > 6 && (
        <div className="more-sellers">
          <button onClick={handleShowAll} className="btn more-btn mt-4">
            More
          </button>
        </div>
      )}
    </section>
  );
}