import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { fetchUserData } from '../../Features/user/userSlice';

export default function Protected({children, role}) {
  const dispatch = useDispatch();
  const { user, isLoading } = useSelector((state) => state.user);
  const [isUserChecked, setIsUserChecked] = useState(false);

  useEffect(() => {
      if (!user) {
          dispatch(fetchUserData()).then(() => {
              setIsUserChecked(true);
          });
      } else {
          setIsUserChecked(true);
      }
  }, [dispatch, user]);

  if (isLoading || !isUserChecked) {
      return <div>Loading...</div>;
  }

  if (!user || user.userType !== role) {
      return <Navigate to='/' />;
  }

  return children;
}
