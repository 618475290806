import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API } from '../../API';

const setAuthHeader = () => {
    const user = localStorage.getItem('user');
    const { token } = user ? JSON.parse(user) : {};
    // console.log('localStorage token ', token);
  
    return {
      headers: {
        Authorization: token ? `Bearer ${token}` : '',  
      },
    };
};


// Customer: Place an order
export const placeOrder = createAsyncThunk(
  'orders/placeOrder',
  async (orderData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${API}/api/customer/placeYourOrder`,
         orderData,
         setAuthHeader()
        );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Customer: Get order history
export const getOrderHistory = createAsyncThunk(
  'orders/getOrderHistory',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API}/api/customer/viewYourOrder`,
        setAuthHeader()
    );
    console.log('Order History ',response.data);
    
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Seller: Get all orders


const orderSlice = createSlice({
  name: 'orders',
  initialState: {
    order: null,
    orders: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Handle placeOrder
    builder
      .addCase(placeOrder.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(placeOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.order = action.payload;
      })
      .addCase(placeOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Handle getOrderHistory
    builder
      .addCase(getOrderHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOrderHistory.fulfilled, (state, action) => {
        state.loading = false;
        console.log("action.payload ",action.payload);
        state.orders = action.payload;
        console.log("state.orders ",state.orders);
      })
      .addCase(getOrderHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default orderSlice.reducer;
