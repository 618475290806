import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API } from '../../API';

export const fetchTransactions = createAsyncThunk(
  'fetchTransactions',
  async (pagination, { rejectWithValue }) => {
      try {
          const data = await axios.get(`${API}/transactions?skip=${pagination.skip}&limit=${pagination.limit}`,{withCredentials: true}
          );
          return data;
      } catch (error) {
          if (error.response) {
              return rejectWithValue(error.response.data);
          } else if (error.request) {
              return rejectWithValue("No response received from server");
          } else {
              return rejectWithValue("Error setting up request");
          }
      }
  }
);

const transaction = createSlice({
    name: 'transaction',
    initialState: {
      transaction: null,
      transactionHistory: [],
      transactionHistoryCount: 0,
      loading: false,
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchTransactions.pending, (state) => {
          state.listLoading = true;
          state.error = null;
        })
        .addCase(fetchTransactions.fulfilled, (state, action) => {
          state.listLoading = false;
          state.transactionHistory = state.transactionHistory.concat(...action.payload.data.doc);
          state.transactionHistoryCount = action.payload.data.count;
        })
        .addCase(fetchTransactions.rejected, (state, action) => {
          state.listLoading = false;
          state.error = action.payload;
        });
    },
});
  
export default transaction.reducer;
