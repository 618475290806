import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus, faFilter } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { addProduct, deleteProduct, updateProduct, viewProducts } from "../../Features/seller/sellerSlice";
import { toast, Slide } from "react-toastify";
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'

const categories = [
  "Vegetables",
  "Fruits",
  "Dairy Products",
  "Bakery Products",
  "Snacks",
  "Beverages",
  "Spices",
  "Ready-to-Eat",
  "Frozen Food"
];

export default function SellerProducts() {
  const [products, setProducts] = useState([]);
  const [editingProduct, setEditingProduct] = useState(null);
  const [newProduct, setNewProduct] = useState({
    name: "",
    price: "",
    stock: "",
    image: "",
    category: "",
  });
  const [filterCategory, setFilterCategory] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const sellerProduct = useSelector((state) => state.seller.products.products);
  const dispatch = useDispatch();

  const getAllProducts = async () => {
    try {
      const data = await dispatch(viewProducts());
      console.log("viewProducts ", data);
    } catch (error) {
      console.log("Something went wrong in viewProducts ", error);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);
  

  const handleEdit = (product) => {
    setEditingProduct(product);
    setIsEditModalOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      const res = await dispatch(deleteProduct(id));
      if (res.payload.status) {
        toast.success(res.payload.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Slide,
        });
        getAllProducts();
      } else {
        toast.error(res.payload.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Slide,
        });
      }
    } catch (error) {
      console.log("Something went wrong in deleteProduct ", error);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const productData = {
      name: editingProduct.name,
      price: editingProduct.price,
      stock: editingProduct.stock,
      image: editingProduct.image,
      category: editingProduct.category,
    };
    
    try {
      const res = await dispatch(updateProduct({ id: editingProduct._id, productData }));
      if (res.payload.status) {
        toast.success(res.payload.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Slide,
        });
        getAllProducts();
      } else {
        toast.error(res.payload.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Slide,
        });
      }
    } catch (error) {
      console.log("Something went wrong in updateProduct ", error);
    }

    setIsEditModalOpen(false);
    setEditingProduct(null);
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    try {
      const res = await dispatch(addProduct(newProduct));
      if (res.payload.status) {
        toast.success(res.payload.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Slide,
        });
        getAllProducts();
      } else {
        toast.error(res.payload.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Slide,
        });
      }
    } catch (error) {
      console.log("Something went wrong in addProduct ", error);
    }
    setNewProduct({ name: "", price: "", stock: "", image: "", category: "" });
  };

  const filteredProducts = filterCategory
    ? sellerProduct.filter(product => product.category === filterCategory)
    : sellerProduct;

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Products</h2>

      {/* Add New Product Form */}
      <form onSubmit={handleAdd} className="mb-6 bg-white p-4 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-2">Add New Product</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <input
            type="text"
            placeholder="Product Name"
            value={newProduct.name}
            onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
            className="border rounded px-2 py-1"
            required
          />
          <input
            type="number"
            placeholder="Price"
            value={newProduct.price}
            onChange={(e) => setNewProduct({ ...newProduct, price: e.target.value })}
            className="border rounded px-2 py-1"
            required
          />
          <input
            type="number"
            placeholder="Stock"
            value={newProduct.stock}
            onChange={(e) => setNewProduct({ ...newProduct, stock: e.target.value })}
            className="border rounded px-2 py-1"
            required
          />
          <input
            type="text"
            placeholder="Image URL"
            value={newProduct.image}
            onChange={(e) => setNewProduct({ ...newProduct, image: e.target.value })}
            className="border rounded px-2 py-1"
          />
          <select
            value={newProduct.category}
            onChange={(e) => setNewProduct({ ...newProduct, category: e.target.value })}
            className="border rounded px-2 py-1"
            required
          >
            <option value="">Select Category</option>
            {categories.map((category) => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>
        </div>
        <button type="submit" className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Add Product
        </button>
      </form>

      {/* Filter */}
      <div className="mb-4 flex items-center">
        <FontAwesomeIcon icon={faFilter} className="mr-2" />
        <select
          value={filterCategory}
          onChange={(e) => setFilterCategory(e.target.value)}
          className="border rounded px-2 py-1"
        >
          <option value="">All Categories</option>
          {categories.map((category) => (
            <option key={category} value={category}>{category}</option>
          ))}
        </select>
      </div>

      {/* Product List */}
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Image</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Stock</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Category</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredProducts && filteredProducts.map((product) => (
              <tr key={product._id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <img
                    src={product.image || "/placeholder.svg?height=100&width=100"}
                    alt={product.name || "No Name"}
                    className="h-16 w-16 object-cover rounded"
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap">{product.name}</td>
                <td className="px-6 py-4 whitespace-nowrap">₹{product.price && product.price.toFixed(2)}</td>
                <td className="px-6 py-4 whitespace-nowrap">{product.stock}</td>
                <td className="px-6 py-4 whitespace-nowrap">{product.category}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <button onClick={() => handleEdit(product)} className="text-blue-600 hover:text-blue-900 mr-2">
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  <button onClick={() => handleDelete(product._id)} className="text-red-600 hover:text-red-900">
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Edit Product Modal */}
      <Transition appear show={isEditModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsEditModalOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Edit Product
                  </Dialog.Title>
                  <form onSubmit={handleUpdate} className="mt-2">
                    <div className="grid grid-cols-1 gap-4">
                      <input
                        type="text"
                        placeholder="Product Name"
                        value={editingProduct?.name || ''}
                        onChange={(e) => setEditingProduct({ ...editingProduct, name: e.target.value })}
                        className="border rounded px-2 py-1"
                        required
                      />
                      <input
                        type="number"
                        placeholder="Price"
                        value={editingProduct?.price || ''}
                        onChange={(e) => setEditingProduct({ ...editingProduct, price: e.target.value })}
                        className="border rounded px-2 py-1"
                        required
                      />
                      <input
                        type="number"
                        placeholder="Stock"
                        value={editingProduct?.stock || ''}
                        onChange={(e) => setEditingProduct({ ...editingProduct, stock: e.target.value })}
                        className="border rounded px-2 py-1"
                        required
                      />
                      <input
                        type="text"
                        placeholder="Image URL"
                        value={editingProduct?.image || ''}
                        onChange={(e) => setEditingProduct({ ...editingProduct, image: e.target.value })}
                        className="border rounded px-2 py-1"
                      />
                      <select
                        value={editingProduct?.category || ''}
                        onChange={(e) => setEditingProduct({ ...editingProduct, category: e.target.value })}
                        className="border rounded px-2 py-1"
                        required
                      >
                        <option value="">Select Category</option>
                        {categories.map((category) => (
                          <option key={category} value={category}>{category}</option>
                        ))}
                      </select>
                    </div>
                    <div className="mt-4 flex justify-end space-x-2">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                        onClick={() => {
                          setIsEditModalOpen(false)
                          setEditingProduct(null)
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      >
                        Update
                      </button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}