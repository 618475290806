import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast, Slide } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ShoppingCart, CreditCard, Truck, ChevronRight } from "lucide-react";
import { placeOrder } from "../../Features/order/orderSlice";
import { clearCart } from "../../Features/cart/cartSlice";
import { API } from "../../API";

export default function CheckoutPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [paymentMethod, setPaymentMethod] = useState("card");
  const [shippingDetails, setShippingDetails] = useState({
    name: "",
    address: "",
    city: "",
    postalCode: "",
  });
  const [isProcessing, setIsProcessing] = useState(false);

  const cart = useSelector((state) => state.cart.cart);
  const user = useSelector((state) => state.user.user);

  const [products, setProducts] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    const productsFromStorage = JSON.parse(localStorage.getItem("cart")) || [];
    setProducts(cart.length > 0 ? cart : productsFromStorage.cart);
    console.log("productsFromStorage ", cart);
  }, [cart]);

  useEffect(() => {
    const total = products.reduce(
      (sum, item) => sum + item.price * item.quantity,
      0
    );
    setTotalPrice(total);
  }, [products]);

  const steps = [
    { id: 1, name: "Cart", icon: ShoppingCart },
    { id: 2, name: "Shipping", icon: Truck },
    { id: 3, name: "Payment", icon: CreditCard },
  ];

  const setAuthHeader = () => {
    const user = localStorage.getItem("user");
    const { token } = user ? JSON.parse(user) : {};
    // console.log('localStorage token ', token);

    return {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    };
  };

  const handleShippingChange = (e) => {
    setShippingDetails({ ...shippingDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    if (step < 3) {
      setStep(step + 1);
    } else {
      setIsProcessing(true);
      if (paymentMethod === "card") {
        await handleRazorpayPayment();
      } else {
        await handleCODOrder();
      }
      setIsProcessing(false);
    }
  };

  // Razorpay payment handler
  const handleRazorpayPayment = async () => {
    try {
      const orderResponse = await axios.post(
        `${API}/api/payment/createOrder`,
        {
          amount: totalPrice,
          currency: "INR",
          receipt: `order_${Date.now()}`,
          products: products,
          shippingDetails: shippingDetails,
          paymentMethod: paymentMethod,
        },
        setAuthHeader()
      );

      const options = {
        key: "rzp_test_BmKdDG3sTNNI5C", // Razorpay Key ID
        amount: orderResponse.data.order.amount,
        currency: orderResponse.data.order.currency,
        name: "Shriveg's Grocery",
        description: "Payment for your order",
        order_id: orderResponse.data.order.id,
        handler: async function (response) {
          try {
            const verifyResponse = await axios.post(
              `${API}/api/payment/verify`,
              {
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
              },
              setAuthHeader()
            );

            if (verifyResponse.data.status) {
              // await placeOrderAfterPayment(response.razorpay_payment_id);
              toast.success("Payment successful and order placed!", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Slide,
              });
              navigate("/");
              dispatch(clearCart());
            } else {
              toast.error("Payment verification failed", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Slide,
              });
            }
          } catch (error) {
            console.error("Error verifying payment:", error);
            toast.error("Error verifying payment", {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              transition: Slide,
            });
          }
        },
        prefill: {
          name: shippingDetails.name,
          email: user?.email || "",
          contact: user?.phone || "",
        },
        theme: {
          color: "#10B981",
        },
      };

      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (error) {
      console.error("Error creating Razorpay order:", error);
      toast.error("Error creating payment order", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Slide,
      });
    }
  };

  const handleCODOrder = async () => {
    await placeOrderAfterPayment();
  };

  const placeOrderAfterPayment = async (paymentId = null) => {
    if (
      !user?._id ||
      !totalPrice ||
      !shippingDetails.name ||
      !shippingDetails.address ||
      !shippingDetails.city ||
      !shippingDetails.postalCode ||
      !paymentMethod
    ) {
      toast.error("Please fill in all required fields", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Slide,
      });
      return;
    }

    const orderData = {
      userId: user._id,
      products,
      totalPrice,
      shippingDetails: {
        name: shippingDetails.name,
        address: shippingDetails.address,
        city: shippingDetails.city,
        postalCode: shippingDetails.postalCode,
      },
      paymentMethod,
      paymentDetails: paymentMethod === "card" ? { paymentId } : {},
    };

    try {
      const res = await dispatch(placeOrder(orderData));
      if (res.payload.status) {
        toast.success(res.payload.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Slide,
        });
        navigate("/");
        dispatch(clearCart());
      } else {
        toast.error(res.payload.message, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Slide,
        });
      }
    } catch (error) {
      console.log("Error placing order", error);
      toast.error(error.message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Slide,
      });
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex items-center justify-between">
          <div className="flex items-center">
            <svg
              className="h-8 w-8 text-green-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
              />
            </svg>
            <span className="ml-2 text-xl font-semibold text-gray-900">
              Shriveg's Grocery
            </span>
          </div>
        </div>
      </header>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="mb-8">
          <h1 className="text-3xl font-extrabold text-gray-900">Checkout</h1>
        </div>

       
        {products.length > 0 ? <>
          <div className="mb-8">
          <nav aria-label="Progress">
            <ol className="flex items-center">
              {steps.map((stepItem, stepIdx) => (
                <li
                  key={stepItem.name}
                  className={`${
                    stepIdx !== steps.length - 1 ? "pr-8 sm:pr-20" : ""
                  } relative`}
                >
                  <button
                    onClick={() => setStep(stepItem.id)}
                    className="flex items-center group"
                    disabled={stepItem.id > step}
                  >
                    <div
                      className={`${
                        step >= stepItem.id ? "bg-green-600" : "bg-gray-300"
                      } h-10 w-10 rounded-full flex items-center justify-center ${
                        stepItem.id <= step
                          ? "cursor-pointer"
                          : "cursor-not-allowed"
                      }`}
                    >
                      <stepItem.icon className="w-6 h-6 text-white" />
                    </div>
                    <p
                      className={`${
                        step >= stepItem.id ? "text-green-600" : "text-gray-500"
                      } ml-4 text-sm font-medium`}
                    >
                      {stepItem.name}
                    </p>
                  </button>
                  {stepIdx !== steps.length - 1 ? (
                    <div className="hidden sm:block absolute top-0 right-0 h-full w-5">
                      <ChevronRight className="h-full w-5 text-gray-300" />
                    </div>
                  ) : null}
                </li>
              ))}
            </ol>
          </nav>
        </div>

          
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              {step === 1 && (
                <div>
                  <h2 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                    Your Cart
                  </h2>
                  <ul className="divide-y divide-gray-200">
                    {products &&
                      products.map((item) => (
                        <li
                          key={item._id}
                          className="py-4 flex justify-between"
                        >
                          <div>
                            <p className="font-medium">{item.name}</p>
                            <p className="text-sm text-gray-500">
                              Quantity: {item.quantity}
                            </p>
                          </div>
                          <p className="font-medium">
                            ₹{(item.price * item.quantity).toFixed(2)}
                          </p>
                        </li>
                      ))}
                  </ul>
                  <div className="mt-4 flex justify-between">
                    <p className="font-medium">Total:</p>
                    <p className="font-medium">₹{totalPrice.toFixed(2)}</p>
                  </div>
                  <div className="flex justify-between mt-4">
                    <button
                      onClick={() => navigate("/")}
                      className="bg-gray-300 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                    >
                      Back to Shop
                    </button>
                    <button
                      onClick={() => setStep(step + 1)}
                      className="bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    >
                      Proceed to Shipping
                    </button>
                  </div>
                </div>
              )}

              {step === 2 && (
                <div>
                  <h2 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                    Shipping Details
                  </h2>
                  <div className="mt-4 space-y-4">
                    <div>
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Full Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        required
                        value={shippingDetails.name}
                        onChange={handleShippingChange}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Address
                      </label>
                      <input
                        type="text"
                        name="address"
                        id="address"
                        required
                        value={shippingDetails.address}
                        onChange={handleShippingChange}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                      />
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label
                          htmlFor="city"
                          className="block text-sm font-medium text-gray-700"
                        >
                          City
                        </label>
                        <input
                          type="text"
                          name="city"
                          id="city"
                          required
                          value={shippingDetails.city}
                          onChange={handleShippingChange}
                          className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="postalCode"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Postal Code
                        </label>
                        <input
                          type="text"
                          name="postalCode"
                          id="postalCode"
                          required
                          value={shippingDetails.postalCode}
                          onChange={handleShippingChange}
                          className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between mt-4">
                    <button
                      onClick={() => setStep(step - 1)}
                      className="bg-gray-300 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                    >
                      Back
                    </button>
                    <button
                      onClick={() => setStep(step + 1)}
                      className="bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    >
                      Proceed to Payment
                    </button>
                  </div>
                </div>
              )}

              {step === 3 && (
                <div>
                  <h2 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                    Payment Method
                  </h2>
                  <div className="space-y-4">
                    <label className="flex items-center space-x-3">
                      <input
                        type="radio"
                        name="paymentMethod"
                        value="card"
                        required
                        checked={paymentMethod === "card"}
                        onChange={(e) => setPaymentMethod(e.target.value)}
                        className="form-radio h-5 w-5 text-green-600"
                      />
                      <span className="text-gray-900 font-medium">
                        Pay with Razorpay
                      </span>
                    </label>
                    <label className="flex items-center space-x-3">
                      <input
                        type="radio"
                        name="paymentMethod"
                        value="cod"
                        required
                        checked={paymentMethod === "cod"}
                        onChange={(e) => setPaymentMethod(e.target.value)}
                        className="form-radio h-5 w-5 text-green-600"
                      />
                      <span className="text-gray-900 font-medium">
                        Cash on Delivery (COD)
                      </span>
                    </label>
                  </div>

                  <div className="flex justify-between mt-4">
                    <button
                      onClick={() => setStep(step - 1)}
                      className="bg-gray-300 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                    >
                      Back
                    </button>
                    <button
                      onClick={handleSubmit}
                      className="bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    >
                      {isProcessing
                        ? "Processing..."
                        : paymentMethod === "card"
                        ? "Pay Now"
                        : "Place Order"}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          </> : 
          <div className="bg-white px-8 py-10 text-center shadow overflow-hidden sm:rounded-lg">
            <p>Your cart is empty</p>
          </div>
        }
      </main>
    </div>
  );
}
