import { configureStore } from '@reduxjs/toolkit'
import userReducer from '../Features/user/userSlice'
import cartReducer from '../Features/cart/cartSlice'
import sellerReducer from '../Features/seller/sellerSlice'
import orderReducer from '../Features/order/orderSlice'
import adminReducer from '../Features/admin/adminSlice'
import walletReducer from '../Features/wallet/walletSlice';
import transactionReducer from '../Features/transaction/transactionSlice';

export const store= configureStore({
    reducer:{
       user:userReducer,
       cart:cartReducer,
       seller:sellerReducer,
       orders:orderReducer,
       admin:adminReducer,
       wallet:walletReducer,
       transaction:transactionReducer
    }
})