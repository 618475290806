import React from "react";

const RefundCancellationPolicy = () => {
  return (
    <div className="container mx-auto px-24 py-10 mt-24 bg-white">
      <h1 className="text-4xl font-bold text-center text-green-800 mb-10">Refund and Cancellation Policy</h1>

      <h2 className="text-3xl font-semibold text-green-700 underline mt-6 mb-4">Return</h2>
      <p className="text-gray-800 mb-4 text-2xl">
        Product must be returned to us within the same day from the date it has been delivered to the customer. Product must be returned with all tags attached in its original condition along with all packing material, courier receipt, invoice & other papers.
      </p>

      <h2 className="text-3xl font-semibold text-green-700 underline mt-6 mb-4">Refund</h2>
      <p className="text-gray-800 mb-4 text-2xl">
        Once the Product is received to the company successfully, Shriveg Homemade Service Pvt Ltd will instantly initiate the refund to your source account or chosen method of refund within 2 working days.
      </p>

      <h2 className="text-3xl font-semibold text-green-700 underline mt-6 mb-4">Refund and Cancellation for Service Provider Company</h2>
      <p className="text-gray-800 mb-4 text-2xl">
        Due to service providers in nature, <span className="font-bold">“NO REFUND”</span> and <span className="font-bold">“NO CANCELLATION”</span> will be entertained once the payment has been made.
      </p>

      <h2 className="text-3xl font-semibold text-green-700 underline mt-6 mb-4">Cancellation Policy</h2>
      <p className="text-gray-800 mb-4 text-2xl">
        Please note an order can only be canceled within 24 hours of placing the order. Once the order is processed after 24 hours, no cancellation request will be entertained. However, return is possible for all orders/products.
      </p>

      <p className="text-gray-800 mb-4 text-2xl">
        OR<br />
        Customers can <span className="font-bold">CANCEL</span> the order only before the order has been shipped/dispatched. After the product(s) have been shipped, the customer <span className="font-bold">CANNOT</span> cancel the orders. However, return is possible for all orders/products.
      </p>
    </div>
  );
};

export default RefundCancellationPolicy;
