import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { clearReferState, fetchReferAndEarn } from '../../Features/user/userSlice';
import Loader from '../Loader/Loader';

export default function ReferEarn() {
  const dispatch = useDispatch();
  const { user, referandearns, isReferLoading, referListCount } = useSelector((state) => state.user);

  const [isCopied, setIsCopied] = useState(false);
  const [skip, setSkip] = useState(0);
  const [limit] = useState(10);
  const [hasMore, setHasMore] = useState(true);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`https://shriveg.com/auth?referral_code=${user.referral_code}`)
      .then(() => {
        setIsCopied(true);
        toast.success('Referral code copied to clipboard!', {
          position: "bottom-center",
          autoClose: 3000,
          theme: "dark",
        });
      })
      .catch((error) => {
        toast.error('Failed to copy the referral code.', {
          position: "bottom-center",
          autoClose: 3000,
          theme: "dark",
        });
      });
  };

  const loadMore = () => {
    const nextSkip = skip + limit;
    dispatch(fetchReferAndEarn({ skip, limit })).then(() => {
      setSkip(nextSkip);
  
      if (referListCount > 0 && nextSkip >= referListCount) {
        setHasMore(false);
      }
    });
  };
  
  useEffect(() => {
    if (skip === 0) {
      dispatch(clearReferState());
      loadMore();
    }
  }, []);
  
  if(isReferLoading){
    return <Loader/>
  }

  return (
    <div className="mx-auto py-10">
      <h2 className="text-3xl font-bold mb-4 text-center text-green-700">Refer and Earn</h2>
      <p className="text-gray-700 mb-6 text-center">
        Invite friends to join and earn rewards! Share your unique referral code and get rewarded when they sign up.
      </p>

      <div className="bg-white p-8 rounded-lg shadow-md max-w-md mx-auto text-center mb-8">
        <h3 className="text-xl font-semibold mb-4 text-black">Your Referral Code</h3>
        <div className="flex items-center justify-center mb-4">
          <span className="bg-gray-100 text-black font-mono px-6 py-3 rounded-l-lg border border-r-0 border-gray-300">
            {user?.referral_code}
          </span>
          <button
            onClick={copyToClipboard}
            className="bg-green-600 text-white px-6 py-3 rounded-r-lg border border-l-0 border-green-600 hover:bg-green-700 transition-all duration-300"
          >
            {isCopied ? 'Copied!' : 'Copy Code'}
          </button>
        </div>
        <p className="text-gray-600">
          Share this code with friends to earn rewards. Your friends will get a welcome bonus when they join!
        </p>
      </div>

      <div className="mt-10 p-10">
        <h3 className="text-xl font-semibold mb-4 text-green-700">Refern & Earn History</h3>
        <div className="bg-gray-100 p-6 rounded-lg shadow-md w-full overflow-x-auto">
          <table className="min-w-full text-sm text-left text-gray-600">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-4 py-2 font-semibold">Sr.No</th>
                <th className="px-4 py-2 font-semibold">Date</th>
                <th className="px-4 py-2 font-semibold">Referred By</th>
                <th className="px-4 py-2 font-semibold">Referred To</th>
                <th className="px-4 py-2 font-semibold">Reward Type</th>
                <th className="px-4 py-2 font-semibold">Amount</th>
                <th className="px-4 py-2 font-semibold">Status</th>
              </tr>
            </thead>
            <tbody>
                {referandearns.length > 0 ? (
                  referandearns.map((referHistory, index) => (
                    <tr key={referHistory._id} className="border-b hover:bg-gray-50">
                      <td className="px-4 py-2 font-semibold">{index + 1}</td>
                      <td className="px-4 py-2">
                        {new Date(referHistory.updatedAt).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        })}
                      </td>
                      <td className="px-4 py-2">{referHistory.referred_by?.fullName}</td>
                      <td className="px-4 py-2">{referHistory.referred_to?.fullName}</td>
                      <td className="px-4 py-2">{referHistory.amount_type}</td>
                      <td className="px-4 py-2">{referHistory.reward_amount}</td>
                      <td className={`px-4 py-2 ${referHistory.status === "Successful" ? "text-green-600" : "text-red-600"}`}>
                        {referHistory.status}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="px-4 py-2 text-center text-gray-600">No recharge history available.</td>
                  </tr>
                )}
              </tbody>
          </table>
        </div>
        {hasMore && (
          <div className="flex justify-center mt-6">
            <button
              onClick={loadMore}
              className="bg-green-600 text-white px-6 py-3 rounded-lg hover:bg-green-700 transition-all duration-300"
            >
              Load More
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
