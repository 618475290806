import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { X } from 'lucide-react';
import { removeFromCart, incrementQuantity, decrementQuantity } from '../../../Features/cart/cartSlice';
import { Link, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { toast, Slide } from 'react-toastify';

export default function ShoppingCart({ isOpen, closeDrawer }) {
  const cart = useSelector((state) => state.cart.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user= useSelector((state)=> state.user.user)  

  const handleRemoveFromCart = (id) => {
    dispatch(removeFromCart(id));
  };

  const handleIncrement = (id) => {
    dispatch(incrementQuantity(id));
  };

  const handleDecrement = (id) => {
    dispatch(decrementQuantity(id));
  };

  const handleClick= ()=>{
    closeDrawer();
    if(!user){
      toast.error('Please login', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Slide,
      });
      navigate('/auth');
    } else {
      navigate('/checkout')
    }
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ x: '100%' }}
          animate={{ x: 0 }}
          exit={{ x: '100%' }}
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          className="fixed inset-y-0 right-0 z-50 w-full sm:w-96 bg-white shadow-lg"
        >
          <div className="h-full flex flex-col">
            <div className="flex justify-between items-center p-4 border-b">
              <h2 className="text-2xl font-semibold">Shopping Cart</h2>
              <button onClick={closeDrawer} className="text-gray-500 hover:text-gray-700">
                <X size={24} />
              </button>
            </div>

            <div className="flex-grow overflow-y-auto p-4">
              {cart.length > 0 ? (
                <div className="space-y-6">
                  {cart.map((item) => (
                    <div key={item.id} className="flex space-x-4 items-center">
                      <img src={item.image} alt={item.name} className="w-20 h-20 object-cover rounded" />
                      <div className="flex-1">
                        <h3 className="font-semibold">{item.name}</h3>
                        <div className="flex items-center mt-2">
                          <button
                            onClick={() => handleDecrement(item.id)}
                            className="px-2 py-1 bg-gray-200 rounded-l hover:bg-gray-300"
                          >
                            -
                          </button>
                          <span className="px-4 py-1 bg-white border-t border-b">{item.quantity}</span>
                          <button
                            onClick={() => handleIncrement(item.id)}
                            className="px-2 py-1 bg-gray-200 rounded-r hover:bg-gray-300"
                          >
                            +
                          </button>
                        </div>
                        <button
                          onClick={() => handleRemoveFromCart(item.id)}
                          className="text-purple-600 hover:text-purple-800 mt-2 block"
                        >
                          Remove
                        </button>
                      </div>
                      <div>
                        <span className="font-semibold">₹{item.totalPrice}/-</span>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p>Your cart is empty</p>
              )}
            </div>

            {cart.length > 0 && (
              <div className="p-4 border-t">
                <p className="text-right mb-4">Total: ₹{cart.reduce((acc, item) => acc + item.totalPrice, 0)}/-</p>
                  <button
                    onClick={handleClick}
                    className="w-full bg-black text-white py-3 rounded hover:bg-gray-800 transition duration-200"
                  >
                    Checkout
                  </button>
              </div>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

ShoppingCart.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
};
