import React from "react";

const ShippingDeliveryPolicy = () => {
  return (
    <div className="container mx-auto px-24 py-10 mt-24 bg-white">
      <h1 className="text-4xl font-bold text-center text-green-800 mb-10">Shipping & Delivery Policies</h1>

      <p className="text-gray-800 mb-4 text-2xl">
        Shriveg Homemade Service Pvt Ltd ships its products to almost all parts of India. Orders placed will be shipped within 24 hours. We ship on all days except Sunday and National Holidays.
      </p>

      <p className="text-gray-800 mb-4 text-2xl">
        For all areas serviced by reputed couriers, the delivery time would be within 3 to 4 business days of shipping (business days exclude Sundays and other holidays). For other areas, the products will be shipped through local couriers and may take 1-2 weeks depending on location.
      </p>

      <p className="text-gray-800 mb-4 text-2xl">
        At times, there might be unexpected delays in the delivery of your order due to unavoidable and undetermined logistics challenges beyond our control, for which Shriveg Homemade Service Pvt Ltd is not liable. We request our users to cooperate as Shriveg Homemade Service Pvt Ltd continuously tries to mitigate such instances.
      </p>

      <p className="text-gray-800 mb-4 text-2xl">
        Additionally, Shriveg Homemade Service Pvt Ltd reserves the right to cancel your order at its sole discretion in cases where it takes longer than usual delivery time or the shipment is physically untraceable. In such cases, the amount paid for the cancelled product(s) will be refunded to your source account.
      </p>
    </div>
  );
};

export default ShippingDeliveryPolicy;
