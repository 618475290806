import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSellerOrders, updateOrderStatus } from '../../Features/seller/sellerSlice';
import OrderModal from './SellerOrderModal';
import Loader from '../Loader/Loader';

export default function SellerOrders() {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.seller.orders);
  const loading = useSelector((state) => state.seller.loading);
  const error = useSelector((state) => state.seller.error);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getSellerOrders());
  }, [dispatch])

  const getStatusColor = (status) => {
    switch (status) {
      case 'Waiting for delivery partner':
        return 'bg-yellow-100 text-yellow-800';
      case 'On the way':
        return 'bg-blue-100 text-blue-800';
      case 'Delivered':
        return 'bg-green-100 text-green-800';
      case 'Cancelled':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const handleStatusChange = async (orderId, newStatus) => {
    try{
      
      
      const res= await dispatch(updateOrderStatus({ orderId, status: newStatus }))
      console.log("handleStatusChange ",res);
      
    }
    catch(error){
      console.log("Something went wrong in handleStatusChange() ",error);
      window.location.reload(true)
    }
    
  };

  const openModal = (order) => {
    setSelectedOrder(order);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedOrder(null);
    setIsModalOpen(false);
  };

  if (loading) {
    <Loader/>
  }

  return (
    <div className="container mx-auto py-10">
      <h2 className="text-2xl font-bold mb-4">Orders</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Order ID</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 text-lg">
            {orders && orders.orders.map((order) => (
              <tr key={order._id}>
                <td className="px-6 py-4 whitespace-nowrap">{order._id}</td>
                <td className="px-6 py-4 whitespace-nowrap">{order.customerName}</td>
                <td className="px-6 py-4 whitespace-nowrap">{new Date(order.updatedAt).toLocaleDateString()}</td>
                <td className="px-6 py-4 whitespace-nowrap">₹{order.totalPrice}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <select
                    value={order.status}
                    onChange={(e) => handleStatusChange(order._id, e.target.value)}
                    className={`block w-full pl-3 pr-10 py-2 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md ${getStatusColor(order.status)}`}
                  >
                    <option value="Pending" className="bg-yellow-100 text-yellow-800">{order.status}</option>
                    <option value="Waiting for delivery partner" className="bg-blue-100 text-blue-800">Waiting for delivery partner</option>
                    <option value="On the way" className="bg-blue-100 text-blue-800">On the way</option>
                    <option value="Delivered" className="bg-green-100 text-green-800">Delivered</option>
                    <option value="Cancelled" className="bg-red-100 text-red-800">Cancelled</option>
                  </select>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <button
                    onClick={() => openModal(order)}
                    className="bg-white hover:bg-[#065912] hover:text-white text-black font-bold py-2 px-4 rounded"
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <OrderModal show={isModalOpen} handleClose={closeModal} orders={selectedOrder} />
    </div>
  );
}