import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API } from '../../API';


const setAuthHeader = () => {
  const user = localStorage.getItem('user');
  const { token } = user ? JSON.parse(user) : {};
  // console.log('localStorage token ', token);

  return {
    headers: {
      Authorization: token ? `Bearer ${token}` : '',  // Add fallback for empty token
    },
  };
};


// Add Product
export const addProduct = createAsyncThunk(
  'sellers/addProduct',
  async (productData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${API}/api/sellers/addProduct`,
        productData,
        setAuthHeader() // Authorization header here
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Update Product
export const updateProduct = createAsyncThunk(
  'sellers/updateProduct',
  async ({ id, productData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${API}/api/sellers/updateProduct/${id}`,
        productData,
        setAuthHeader() //Authorization header here
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete Product
export const deleteProduct = createAsyncThunk(
  'sellers/deleteProduct',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${API}/api/sellers/remove/${id}`,
        setAuthHeader() // Authorization header here
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// View Products
export const viewProducts = createAsyncThunk(
  'sellers/viewProducts',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API}/api/sellers/my-products`,
        setAuthHeader() // Authorization header
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// Category filter
export const filterCategory= createAsyncThunk(
  'seller/filterCategory',
  async (category, { rejectWithValue })=>{
    try{
      const response= await axios.get(
        `${API}/api/sellers/category/${category}`,
        setAuthHeader()
      )
      return response.data
    }
    catch(error){
      return rejectWithValue(error.response.data);
    }
  }
)

export const getSellerOrders = createAsyncThunk(
  'orders/getSellerOrders',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API}/api/sellers/myOrders`,
        setAuthHeader()
      );
      console.log("getSellerOrders ",response);
      
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Seller: Update order status
export const updateOrderStatus = createAsyncThunk(
  'orders/updateOrderStatus',
  async ({ orderId, status }, { rejectWithValue }) => {
    console.log("orderId ",orderId);
      console.log("newStatus ",status);
    try {
      const response = await axios.patch(`${API}/api/sellers/updateOrderStatus/${orderId}`,{status} ,
        setAuthHeader()
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Seller Slice
const sellerSlice = createSlice({
  name: 'seller',
  initialState: {
    products: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetSellerProducts: (state) => {
      console.log("Before reset (parsed):", JSON.parse(JSON.stringify(state.products)));
      state.products = [];
      console.log("After reset (parsed):", JSON.parse(JSON.stringify(state.products)));
    }
  },
  extraReducers: (builder) => {
    // Add Product
    builder
      .addCase(addProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.loading = false;
        const {product}= action.payload
        // console.log("Product add ",product);
        state.products.products.push(product);  
      })      
      .addCase(addProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Update Product
    builder
      .addCase(updateProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.loading = false;
        console.log("state product ",state.products)
        const index = state.products.products.findIndex((p) => p._id === action.payload._id);
        if (index !== -1) {
          state.products.products[index] = action.payload.product; 
        }
      })      
      .addCase(updateProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Delete Product
    builder
      .addCase(deleteProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.loading = false;
        const {productId}= action.payload
        // console.log("Product delete ",productId);
        state.products = state.products.products.filter((p) => p._id !== productId);
      })      
      .addCase(deleteProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // View Products
    builder
      .addCase(viewProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(viewProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload;
      })
      .addCase(viewProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder
      .addCase(filterCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(filterCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload;
      })
      .addCase(filterCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
      // Handle getSellerOrders
    builder
      .addCase(getSellerOrders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSellerOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.orders = action.payload;
      })
      .addCase(getSellerOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Handle updateOrderStatus
    builder
      .addCase(updateOrderStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateOrderStatus.fulfilled, (state, action) => {
        state.loading = false;
        const updatedOrder = action.payload;
        const index = state.orders.findIndex(order => order._id === updatedOrder._id);
        if (index !== -1) {
          state.orders[index] = updatedOrder;
        }
      })
      .addCase(updateOrderStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetSellerProducts } = sellerSlice.actions;
export default sellerSlice.reducer;