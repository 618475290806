import React, { useState } from 'react';
import './SellerRegister.css'; // Import the CSS file
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { fetchUserData, sellerLogin, sellerRegistration } from "../../Features/user/userSlice";
import 'react-toastify/dist/ReactToastify.css';
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const Registration = () => {
  const query = useQuery();
  const referral_code = query.get('referral_code');
  const [loading, setLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(referral_code ? false : true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  

  const { isLoading, isError, isAuthLoading } = useSelector((state) => state.user);

  const [formData, setFormData] = useState({
    userType: "customer",
    email: {
      value: "",
      isVerified: false,
    },
    phone: {
      value: "",
      isVerified: false,
    },
    fullName: "",
    password: "",
    referral_code: referral_code || '',
  });
  

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let res;
      if (isLogin) {
        res = await dispatch(sellerLogin(formData));
        console.log("test", sellerLogin.fulfilled.match(res));
        if(sellerLogin.fulfilled.match(res)) {
          const user = await dispatch(fetchUserData());
          if(user.payload.data && user.payload.data.userType === "seller") {
            navigate('/seller');
          }
          
          if(user.payload.data && user.payload.data.userType === "customer") {
            navigate('/dashboard');
          }

          if(user.payload.data && user.payload.data.userType === "admin") {
            navigate('/admin');
          }
        } else {
          toast.error(res.payload.details[0].message, {
            position: "bottom-center",
            autoClose: 5000,
            theme: "dark",
          });
        }
        
      } else {
        const registerRes = await dispatch(sellerRegistration(formData));
        if (sellerRegistration.fulfilled.match(registerRes)) {
          const user = await dispatch(fetchUserData());
          if(user.payload.data && user.payload.data.userType === "seller") {
            navigate('/seller');
          }
          
          if(user.payload.data && user.payload.data.userType === "customer") {
            navigate('/dashboard');
          }
        } else {
          toast.error(registerRes.payload.details[0].message, {
            position: "bottom-center",
            autoClose: 5000,
            theme: "dark",
          });
        }
      }
    } catch (error) {
      console.log('Login failed', error);
      toast.error("An error occurred.", {
        position: "bottom-center",
        autoClose: 5000,
        theme: "dark",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="registration-page">
      <div className="form-container">
        <h2 className="form-title">{isLogin ? "Login" : "Create Your Account"}</h2>
        <form onSubmit={submitHandler} className="registration-form">
            {!isLogin && (
              <div>
                <label htmlFor="userType" className="block mb-2 text-sm font-medium text-gray-900">
                  Register As
                </label>

                <div className="flex gap-5 pb-3">
                  <div>
                    <input
                      type="radio"
                      checked={formData.userType === "customer"}
                      onChange={changeHandler}
                      disabled={loading}
                      className="mr-2 text-primary focus:outline-none focus:ring-0"
                      value="customer"
                      name="userType"
                      id="customer"
                    />
                    <label htmlFor="customer">Customer</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      checked={formData.userType === "seller"}
                      onChange={changeHandler}
                      disabled={loading}
                      className="mr-2 text-primary focus:outline-none focus:ring-0"
                      value="seller"
                      name="userType"
                      id="seller"
                    />
                    <label htmlFor="seller">Seller</label>
                  </div>
                </div>
              </div>
            )}

            {!isLogin && (
              <div className="form-group">
                <label className="input-label">Full Name*</label>
                <input
                  type="text"
                  name="fullName"
                  placeholder=" "
                  required
                  onChange={changeHandler}
                  disabled={loading}
                  className="input-field"
                />
              </div>
            )}

            {!isLogin && (
                <div className="form-group">
                  <label className="input-label">Email (Optional)</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    disabled={loading}
                    className="input-field"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        email: { value: e.target.value, isVerified: false },
                      })
                    }
                  />
                </div>
            )}

            <div className="form-group">
              <label className="input-label">Phone Number*</label>
              <input
                type="tel"
                name="phone"
                maxLength="10"
                onInput={(e) => {
                  const value = e.target.value;
                  const sanitizedValue = value.replace(/[^6-9\d]/g, "");
                  if (sanitizedValue !== value) {
                    e.target.value = sanitizedValue;
                  }
                  if (/^[6-9]\d{0,9}$/.test(sanitizedValue)) {
                    setFormData({
                      ...formData,
                      phone: { value: sanitizedValue, isVerified: false },
                    });
                  }
                }}
                required
                disabled={loading}
                className="input-field"
                pattern="^[6-9]\d{9}$"
                title="Please enter a valid 10-digit Indian mobile number starting with 6, 7, 8, or 9."
              />
            </div>

          
            <div className="form-group">
              <label className="input-label">Password*</label>
              <input
                type="password"
                name="password"
                placeholder=" "
                required={!isLogin}
                onChange={changeHandler}
                disabled={loading}
                className="input-field"
              />
            </div>
            {isAuthLoading}
            <button type="submit" disabled={loading} className="submit-button">
              {isAuthLoading ? <span className="spinner"></span> : isLogin ? "Login" : "Register"}
            </button>
        </form>

        <div className="modal-footer text-center py-3">
          {isLogin ? (
            <>
              Don't have an account?{" "}
              <button onClick={() => setIsLogin(false)} className="toggle-link">Register here</button>
            </>
          ) : (
            <>
              Already have an account?{" "}
              <button onClick={() => setIsLogin(true)} className="toggle-link">Login here</button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Registration;
